import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import EmployeeDetailInfoCard from '../../components/EmployeeDetailInfoCard/EmployeeDetailInfoCard';
import EmployeeDetailHoursWorkedChart from '../../components/EmoloyeeDetailHoursWorkedChart/EmoloyeeDetailsHoursWorkedChart';
//import EmployeeDetailUsedLeaveCircle from '../../components/EmployeeDetailUsedLeaveCircle/EmployeeDetailUsedLeaveCircle';
import EmployeeDetailIsClockedIn from '../../components/EmployeeDetailIsClockedIn/EmployeeDetailIsClockedIn';
import EmployeeDetailEarnings from '../../components/EmployeeDetailEarnings/EmployeeDetailEarnings';
import { getEmployeeById, getHoursWorkedPerDayByEmployeeId } from '../../apiService/apiService';
import Loader from '../../components/Loader/Loader';
import './EmployeeDetail.css';

const EmployeeDetail = () => {
    const { employeeId } = useParams();

    const [employee, setEmployee] = useState(null);
    const [hoursWorkedData, setHoursWorkedData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEmployee = async () => {
            setLoading(true);
            try {
                //Maybe skip this api call, since we can pass the entire employeedata from the overview?
                const employeeData = await getEmployeeById(employeeId);
                setEmployee(employeeData);

                const hoursWorkedData = await getHoursWorkedPerDayByEmployeeId(employeeId);
                setHoursWorkedData(hoursWorkedData);
            } catch (error) {
                console.error('Failed to fetch employee details:', error);
                setError('Failed to load employee details.');
            } finally {
                setLoading(false);
            }
        };

        fetchEmployee();
    }, [employeeId]);

    if (error) return <div>{error}</div>;
    if (loading) return <Loader isLoading={loading} />;

    return (
        <div className="employee-detail">
            <DashboardNavbar />
            <div className='content'>
                <EmployeeDetailInfoCard
                    employee={employee}
                />
                <EmployeeDetailHoursWorkedChart
                    hoursWorkedData={hoursWorkedData}
                    employeeId={employeeId}
                />
                <EmployeeDetailIsClockedIn employeeId={employeeId} />
                <EmployeeDetailEarnings employeeId={employeeId} paymentType={employee.paymentType} />
            </div>
        </div>
    );
};

export default EmployeeDetail;
