import React from 'react';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import GigsCalendar from '../../components/GigsCalendar/GigsCalendar';
import './Gigs.css';

const Dashboard = () => {
  return (
    <div className="gigs">
      <DashboardNavbar />
      <div className='content'>
        <GigsCalendar />
      </div>
    </div>
  )
};

export default Dashboard;
