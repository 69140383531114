import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './GigsCalendar.css';
import {
  createGig,
  updateGig,
  getAllGigsForLoggedInUser,
  getAllEmployeesForLoggedInUser,
  assignEmployeeToGig,
  getEmployeesForGig,
  unassignEmployeeFromGig
} from '../../apiService/apiService';
import Loader from '../Loader/Loader';

const GigsCalendar = () => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gigData, setGigData] = useState({
    title: '',
    date: '',
    description: '',
    status: '',
    address: '',
  });
  const [gigs, setGigs] = useState([]);
  const [gigsForSelectedDate, setGigsForSelectedDate] = useState([]);
  const [employees, setEmployees] = useState([]); 
  const [assignedEmployees, setAssignedEmployees] = useState({});
  const [editingGigId, setEditingGigId] = useState(null);

  const [loadingGigs, setLoadingGigs] = useState(true);
  const [loadingAssignedEmployees, setLoadingAssignedEmployees] = useState(false);

  // Fetch gigs and employees on initial load
  useEffect(() => {
    const fetchGigsAndEmployees = async () => {
      try {
        const [gigsResponse, employeesResponse] = await Promise.all([
          getAllGigsForLoggedInUser(),
          getAllEmployeesForLoggedInUser(),
        ]);
        setGigs(gigsResponse);
        setEmployees(employeesResponse);
      } catch (error) {
        console.error('Error fetching gigs or employees:', error);
      } finally {
        setLoadingGigs(false);
      }
    };

    fetchGigsAndEmployees();
  }, []);

  // Fetch assigned employees for the selected date
  useEffect(() => {
    const fetchAssignedEmployees = async () => {
      setLoadingAssignedEmployees(true);

      const assigned = {};  // Local variable to hold the assigned employees for each gig
      for (let gig of gigsForSelectedDate) {
        try {
          const response = await getEmployeesForGig(gig.gigId);
          assigned[gig.gigId] = response.employees;
        } catch (error) {
          console.error(`Error fetching employees for gig ${gig.gigId}:`, error);
        }
      }
      setAssignedEmployees(assigned);
      setLoadingAssignedEmployees(false);
    };

    if (gigsForSelectedDate.length) {
      fetchAssignedEmployees();
    }
  }, [gigsForSelectedDate]);

  // Filter and sort gigs for the selected calendar date
  useEffect(() => {
    const filteredGigs = gigs
      .filter(gig => {
        const gigDate = new Date(gig.date).setHours(0, 0, 0, 0);
        const selectedDate = calendarDate.setHours(0, 0, 0, 0);
        return gigDate === selectedDate;
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    setGigsForSelectedDate(filteredGigs);
  }, [calendarDate, gigs]);

  const handleDateChange = (newDate) => {
    setCalendarDate(newDate);
  };

  const handleAddClick = () => {
    setModalOpen(true);
    setEditingGigId(null);
    setGigData({
      title: '',
      date: '',
      description: '',
      status: '',
      address: '',
    });
  };

  const handleEditClick = (gigId) => {
    const gigToEdit = gigs.find(gig => gig.gigId === gigId);
    if (gigToEdit) {
      setModalOpen(true);
      setEditingGigId(gigId);
      const localDate = new Date(gigToEdit.date);
      const adjustedDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
      const formattedDate = adjustedDate.toISOString().slice(0, 16);

      setGigData({
        title: gigToEdit.title,
        date: formattedDate,
        description: gigToEdit.description || '',
        status: gigToEdit.status,
        address: gigToEdit.address || '',
      });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setGigData({
      title: '',
      date: '',
      description: '',
      status: '',
      address: '',
    });
    setEditingGigId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const gigToSubmit = {
        ...gigData,
        date: new Date(gigData.date).toISOString(),
      };

      if (editingGigId) {
        await updateGig(editingGigId, gigToSubmit);
      } else {
        await createGig(gigToSubmit);
      }

      const updatedGigs = await getAllGigsForLoggedInUser();
      setGigs(updatedGigs);

      const filteredGigs = updatedGigs.filter(gig => {
        const gigDate = new Date(gig.date).setHours(0, 0, 0, 0);
        const selectedDate = calendarDate.setHours(0, 0, 0, 0);
        return gigDate === selectedDate;
      });
      setGigsForSelectedDate(filteredGigs);

      handleModalClose();
    } catch (error) {
      console.error('Error submitting gig:', error);
      alert('Failed to save gig. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeSelect = async (gigId, employeeId) => {
    if (employeeId === '') {
      return;
    }

    setLoadingAssignedEmployees(true);

    try {
      const isAlreadyAssigned = assignedEmployees[gigId]?.some(employee => employee.employeeId === employeeId);
      if (isAlreadyAssigned) {
        alert('This employee is already assigned to the gig.');
        setLoadingAssignedEmployees(false);
        return;
      }

      await assignEmployeeToGig(gigId, employeeId);
      // Refresh assigned employees after assignment
      const updatedEmployees = await getEmployeesForGig(gigId);
      setAssignedEmployees({
        ...assignedEmployees,
        [gigId]: updatedEmployees.employees,
      });
    } catch (error) {
      console.error('Error assigning employee:', error);
      alert('Failed to assign employee. Please try again.');
    } finally {
      setLoadingAssignedEmployees(false);
    }
  };

  const handleUnassignEmployee = async (gigId, employeeId) => {
    setLoadingAssignedEmployees(true);

    try {
      await unassignEmployeeFromGig(gigId, employeeId);
      const updatedEmployees = await getEmployeesForGig(gigId);
      setAssignedEmployees({
        ...assignedEmployees,
        [gigId]: updatedEmployees.employees,
      });
    } catch (error) {
      console.error('Error unassigning employee:', error);
      alert('Failed to unassign employee. Please try again.');
    } finally {
      setLoadingAssignedEmployees(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const getAvailableEmployeesForGig = (gigId) => {
    const assignedEmployeeIds = assignedEmployees[gigId]?.map(employee => employee.employeeId) || [];
    return employees.filter(employee => !assignedEmployeeIds.includes(employee.employeeId));
  };

  return (
    <div className="gigs-calendar">
      <div className="sidebar">
        <Calendar onChange={handleDateChange} value={calendarDate} />
        <button className="add-button" onClick={handleAddClick}>Add Gig</button>
      </div>
      <div className="gigs-list">
        {loadingGigs ? (
          <Loader isLoading={loadingGigs} />
        ) : gigsForSelectedDate.length > 0 ? (
          <table className="gigs-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Description</th>
                <th>Address</th>
                <th>Date and Time</th>
                <th>Assign Employee</th>
                <th>Assigned Employees</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {gigsForSelectedDate.map((gig) => (
                <tr key={gig.gigId}>
                  <td>{gig.title}</td>
                  <td>{gig.status}</td>
                  <td>{gig.description || '-'}</td>
                  <td>{gig.address || '-'}</td>
                  <td>{formatDate(gig.date)}</td>
                  <td>
                    <select onChange={(e) => handleEmployeeSelect(gig.gigId, e.target.value)} defaultValue="">
                      <option value="">Select Employee</option>
                      {getAvailableEmployeesForGig(gig.gigId).map((employee) => (
                        <option key={employee.employeeId} value={employee.employeeId}>
                          {employee.firstName} {employee.lastName}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <div className="assigned-employees">
                      {(loadingAssignedEmployees) ? (
                        <Loader isLoading={loadingAssignedEmployees} />
                      ) : (
                        assignedEmployees[gig.gigId] && assignedEmployees[gig.gigId].length > 0 ? (
                          assignedEmployees[gig.gigId].map((employee) => (
                            <div key={employee.employeeId} className="assigned-employee">
                              <span>{employee.firstName} {employee.lastName}</span>
                              <button
                                className="remove-employee"
                                onClick={() => handleUnassignEmployee(gig.gigId, employee.employeeId)}
                              >
                                X
                              </button>
                            </div>
                          ))
                        ) : (
                          <span className="no-assigned">No employees assigned</span>
                        )
                      )}
                    </div>
                  </td>
                  <td>
                    <button className="edit-button" onClick={() => handleEditClick(gig.gigId)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No gigs scheduled for this day.</p>
        )}
      </div>

      {/* Modal for adding/editing gigs */}
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close-x" onClick={handleModalClose}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <h2>{editingGigId ? 'Edit Gig' : 'Add Gig'}</h2>
            <form onSubmit={handleSubmit}>
              <div className="label-and-input-pair">
                <label>Title:</label>
                <input
                  type="text"
                  value={gigData.title}
                  onChange={(e) => setGigData({ ...gigData, title: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Date and Time:</label>
                <input
                  type="datetime-local"
                  value={gigData.date}
                  onChange={(e) => setGigData({ ...gigData, date: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Description:</label>
                <textarea
                  value={gigData.description}
                  onChange={(e) => setGigData({ ...gigData, description: e.target.value })}
                />
              </div>
              <div className="label-and-input-pair">
                <label>Status:</label>
                <select
                  value={gigData.status}
                  onChange={(e) => setGigData({ ...gigData, status: e.target.value })}
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="label-and-input-pair">
                <label>Address:</label>
                <input
                  type="text"
                  value={gigData.address}
                  onChange={(e) => setGigData({ ...gigData, address: e.target.value })}
                />
              </div>
              <button type="submit" disabled={loading}>{loading ? 'Saving...' : 'Save'}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GigsCalendar;
