import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { logoutUser } from '../../apiService/apiService';
import './DashboardNavbar.css';

const DashboardNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logoutUser();
    navigate('/');
  }, [navigate]);

  return (
    <div className="dashboard-navbar">
      {/* Header for the dashboard */}
      <div className="navbar-header">
        Dashboard
      </div>

      {/* Menu component for the burger menu */}
      <Menu left>
        <Link className="menu-item" to="/dashboard">Home</Link>
        <Link className="menu-item" to="/dashboard/profile">Profile</Link>
        <Link className="menu-item" to="/dashboard/settings">Settings</Link>
        <span className="menu-item" onClick={handleLogout}>Logout</span>
      </Menu>

      {/* Navbar links for larger screens */}
      <a className="navbar-links-logo" href="/#">
        <img src="/images/logo/logo.svg" alt="Logo" />
      </a>

      <div className="navbar-links">
        <Link className="navbar-item" to="/dashboard">Home</Link>
        <Link className="navbar-item" to="/dashboard/profile">Profile</Link>
        <Link className="navbar-item" to="/dashboard/settings">Settings</Link>
        <span className="navbar-item" onClick={handleLogout}>Logout</span>
      </div>
    </div>
  );
};

export default DashboardNavbar;
