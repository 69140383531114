import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../apiService/apiService';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setLoading(true); // Set loading state
        setError(null); // Clear previous error

        try {
            await loginUser({ email, password });
            navigate('/dashboard');
        } catch (err) {
            setError(err.message || 'Login failed');
            console.error('Login Error:', err);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div
            className="login"
            style={{
                backgroundImage: "url(images/header/banner.png)",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
            }}
        >
            <Link to="/">
                <img
                    src="/images/logo/logo.svg"
                    alt="Logo"
                    className="logo"
                />
            </Link>
            <div className="container">
                <header>Login</header>
                <form onSubmit={handleSubmit}>
                    <div className="fields">
                        <div className="input-field">
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-field">
                            <label>Password</label>
                            <input
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <button className="nextBtn" type="submit" disabled={loading}>
                        <span className="btnText">{loading ? 'Logging in...' : 'Login'}</span>
                        <i className="uil uil-navigator" />
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </form>
                <div className="signup-prompt">
                    <p>Don't have an account? <a href="/register">Sign Up!</a></p>
                </div>
            </div>
        </div>
    );
};

export default Login;
