import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { addDays, format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import './EmployeeDetailsHoursWorkedChart.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, zoomPlugin);

const EmployeeDetailHoursWorkedChart = ({ hoursWorkedData, employeeId }) => {

  const navigate = useNavigate(); 

  const getLast14Days = () => {
    const today = new Date();
    const days = [];
    for (let i = 0; i < 14; i++) {
      const day = addDays(today, -i);
      days.push(format(day, 'yyyy-MM-dd'));
    }
    return days.reverse();
  };

  const labels = getLast14Days();
  const dataPoints = labels.map(date => {
    const matchingData = hoursWorkedData.find(item => item.date.startsWith(date));
    return matchingData ? matchingData.totalHoursWorked : 0; // Fallback to 0 if no data
  });

  const hasData = dataPoints.some(point => point > 0);

  const [currentRange, setCurrentRange] = useState({ min: 10, max: 13 });

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Hours Worked',
        data: dataPoints,
        backgroundColor: 'rgba(189, 195, 199, 0.8)',
        borderColor: 'rgba(171, 183, 183, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        min: currentRange.min,
        max: currentRange.max,
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  // Pan functions for navigating between days
  const panLeft = () => {
    setCurrentRange(prevRange => ({
      min: prevRange.min - 1,
      max: prevRange.max - 1,
    }));
  };

  const panRight = () => {
    setCurrentRange(prevRange => ({
      min: prevRange.min + 1,
      max: prevRange.max + 1,
    }));
  };

  // Scroll to the most recent data on load
  useEffect(() => {
    setCurrentRange({ min: 10, max: 13 });
  }, []);

  // Handle wheel event for zooming
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.deltaY < 0 && currentRange.min > 0) {
        panLeft();
      } else if (event.deltaY > 0 && currentRange.max < labels.length - 1) {
        panRight();
      }
      event.preventDefault();
    };

    const chartContainer = document.querySelector('.scrollable-chart-container');
    chartContainer.addEventListener('wheel', handleWheel);
    return () => {
      chartContainer.removeEventListener('wheel', handleWheel);
    };
  }, [currentRange, labels.length]);

  return (
    <div className="employee-detail-hours-worked-chart">
      <div className="chart-title">Hours Worked In The Last 14 Days</div>
      <div className="chart-container">
        <button onClick={panLeft} disabled={currentRange.min === 0} className="pan-button left">
          <img src="/icons/left-arrow.svg" alt="Pan Left" className="arrow-icon" />
        </button>
        <div className="scrollable-chart-container">
          <Bar data={data} options={options} />
        </div>
        <button onClick={panRight} disabled={currentRange.max === labels.length - 1} className="pan-button right">
          <img src="/icons/right-arrow.svg" alt="Pan Right" className="arrow-icon" />
        </button>
      </div>
      <button
        className="view-more-button"
        onClick={() => navigate(`/dashboard/employeedetail/hoursworked/${employeeId}`)}
        disabled={!hasData} // Disable the button if no valid data
      >
        View More
      </button>
    </div>
  );
};

export default EmployeeDetailHoursWorkedChart;
