import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './EmployeeDetailViewMoreHoursWorkedTable.css';

const EmployeeDetailViewMoreHoursWorkedTable = ({ attendanceData = [] }) => {
    const [date, setDate] = useState(new Date());
    const [selectedAttendance, setSelectedAttendance] = useState([]);

    const attendanceMap = attendanceData.reduce((acc, attendance) => {
        const dateKey = new Date(attendance.clockIn).toLocaleDateString();
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }

        // Calculate hours worked if clockOut is available
        const clockInDate = new Date(attendance.clockIn);
        const clockOutDate = attendance.clockOut ? new Date(attendance.clockOut) : null;
        const hoursWorked = clockOutDate ? ((clockOutDate - clockInDate) / (1000 * 60 * 60)).toFixed(2) : 'N/A';

        // Add hoursWorked to attendance
        acc[dateKey].push({
            ...attendance,
            hoursWorked, // Include the calculated hoursWorked
        });

        return acc;
    }, {});

    const handleDateChange = (date) => {
        setDate(date);
        const selectedDate = date.toLocaleDateString();
        setSelectedAttendance(attendanceMap[selectedDate] || []);
    };

    // Calculate total hours worked for the selected date
    const totalHoursWorked = selectedAttendance.reduce((total, attendance) => {
        const hours = parseFloat(attendance.hoursWorked);
        return total + (isNaN(hours) ? 0 : hours);
    }, 0).toFixed(2);

    return (
        <div className="employee-attendance-details-table">
            <h2 className="attendance-title">Attendance</h2>
            <div className="calendar-container">
                <Calendar
                    className="custom-calendar"
                    onChange={handleDateChange}
                    value={date}
                />
            </div>
            {attendanceData.length === 0 ? (
                <p className="no-records">No attendance records found.</p>
            ) : (
                selectedAttendance.length > 0 ? (
                    <>
                        <div className="attendance-table-container">
                            <table className="attendance-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Clock In</th>
                                        <th>Clock Out</th>
                                        <th>Hours Worked</th>
                                        <th>Signature</th> {/* New column for signature */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedAttendance.map((attendance) => {
                                        const signatureUrl = `https://managerize-ckbpcub2fse8bvcn.westeurope-01.azurewebsites.net/uploads/Signatures/${attendance.attendanceId}signature.png`; // Construct the image URL
                                        return (
                                            <tr key={attendance.attendanceId}>
                                                <td>{new Date(attendance.clockIn).toLocaleDateString()}</td>
                                                <td>{new Date(attendance.clockIn).toLocaleTimeString()}</td>
                                                <td>{attendance.clockOut ? new Date(attendance.clockOut).toLocaleTimeString() : 'Currently clocked in...'}</td>
                                                <td>{attendance.hoursWorked}</td>
                                                <td>
                                                    {attendance.signature && ( // Check if signature exists
                                                        <img 
                                                            src={signatureUrl} 
                                                            alt="Signature" 
                                                            style={{ width: '50px', height: 'auto' }} // Adjust size as needed
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="total-hours">
                            <strong>Total: {totalHoursWorked} hours</strong>
                        </div>
                    </>
                ) : (
                    <p className="no-records">No attendance records found for the selected date.</p>
                )
            )}
        </div>
    );
};

export default EmployeeDetailViewMoreHoursWorkedTable;
