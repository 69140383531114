import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Overview from './pages/Overview/Overview';
import EmployeeDetail from './pages/EmployeeDetail/EmployeeDetail';
import EmployeeDetailViewMoreHoursWorked from './pages/EmployeeDetailViewMoreHoursWorked/EmployeeDetailViewMoreHoursWorked';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Gigs from './pages/Gigs/Gigs';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        
        {/* Protected routes */}
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Overview />
          </ProtectedRoute>
        } />

        <Route path="/dashboard/employeedetail/:employeeId" element={
          <ProtectedRoute>
            <EmployeeDetail />
          </ProtectedRoute>
        } />

        <Route path="/dashboard/employeedetail/hoursworked/:employeeId" element={
          <ProtectedRoute>
            <EmployeeDetailViewMoreHoursWorked />
          </ProtectedRoute>
        } />

        <Route path="/gigs" element={
          <ProtectedRoute>
            <Gigs />
          </ProtectedRoute>
        } />


      </Routes>
    </Router>
  );
}

export default App;
