import React from "react";
import { Link } from 'react-router-dom';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-page">
            <div className="banner">
                <img
                    src="/images/header/banner.png"
                    alt="Banner"
                    className="banner-image"
                />
            </div>

            <div className="terms-page-content">
                <Link to="/">
                    <img
                        src="/images/logo/logo.svg"
                        alt="Logo"
                        className="logo"
                    />
                </Link>

                <div className="terms-container">
                    <header>Terms and Conditions</header>
                    <section>
                        <h2>1. Introduction</h2>
                        <p>
                            These Terms and Conditions ("Terms") govern the use of the services provided by Managerize, a [Company's legal structure, e.g., LLC, Corporation], ("we", "our", "us") through our website [www.managerize.com] ("Website") and any other related services, software, tools, or applications (collectively, "Services"). By accessing or using the Services, you ("User", "You") agree to be bound by these Terms.
                        </p>
                        <p>
                            If you do not agree to these Terms, please do not use the Services.
                        </p>

                        <h2>2. Eligibility</h2>
                        <p>
                            You must be at least 18 years of age and have the legal capacity to enter into binding contracts to use the Services. By using the Services, you represent and warrant that you meet these requirements.
                        </p>

                        <h2>3. Services Provided</h2>
                        <p>
                            Managerize provides workforce management software designed to assist businesses in managing and optimizing their workforce (the "Services"). The Services may include features such as scheduling, time tracking, employee management, reporting, analytics, and other related functionalities.
                        </p>

                        <h2>4. Account Creation</h2>
                        <p>
                            To use the Services, you may be required to create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you suspect unauthorized use of your account.
                        </p>

                        <h2>5. Subscription and Payment</h2>
                        <p>
                            Managerize operates on a subscription-based model. Pricing for the Services will be disclosed at the time of subscription and may vary based on the plan selected. You agree to pay the subscription fees in full for the period you have subscribed to, and any applicable taxes.
                        </p>
                        <p>
                            <strong>Refund Policy:</strong> Subscriptions are billed on a monthly basis. You may request a refund for the current month’s subscription fee, provided the request is made within [X] days of your payment. No refunds will be provided for prior months.
                        </p>
                        <p>
                            <strong>Payment Processor:</strong> Managerize will implement a payment processing system, which may involve third-party payment processors. You agree to comply with the terms and conditions of any third-party providers involved in processing payments.
                        </p>

                        <h2>6. User Responsibilities</h2>
                        <p>
                            You agree to use the Services in accordance with all applicable laws and regulations. Specifically, you agree not to:
                        </p>
                        <ul>
                            <li>Use the Services to transmit or store any unlawful, harmful, or infringing content;</li>
                            <li>Engage in any activity that could harm, disable, or impair the functionality of the Services;</li>
                            <li>Attempt to gain unauthorized access to the Services or any other systems or networks connected to them.</li>
                        </ul>

                        <h2>7. Data Collection and Privacy</h2>
                        <p>
                            Managerize collects and processes user data, which may include personal, business, and usage data. We are committed to protecting your privacy and handling your data responsibly. Please refer to our <Link to="/privacy-policy">Privacy Policy</Link> for detailed information on how we collect, use, and protect your data.
                        </p>
                        <p>
                            <strong>Data Security:</strong> While we take reasonable measures to protect your data, no method of data transmission or storage is completely secure. You acknowledge that we cannot guarantee the security of your data and agree that we are not liable for any unauthorized access, data loss, or breaches.
                        </p>

                        <h2>8. Termination</h2>
                        <p>
                            Either party may terminate this agreement at any time, for any reason, by providing written notice to the other party. Upon termination, your access to the Services will be disabled, and you will be required to pay any outstanding fees for the current billing period. 
                        </p>
                        <p>
                            Managerize reserves the right to suspend or terminate access to the Services for any user who violates these Terms.
                        </p>

                        <h2>9. Intellectual Property</h2>
                        <p>
                            All content, features, and functionality of the Services, including but not limited to software, logos, text, and designs, are the exclusive property of Managerize and are protected by copyright, trademark, and other intellectual property laws. You may not use, copy, or distribute any part of the Services without our express permission.
                        </p>

                        <h2>10. Limitation of Liability</h2>
                        <p>
                            To the fullest extent permitted by law, Managerize, its affiliates, and partners shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits, data, or business opportunities arising from your use or inability to use the Services, even if we have been advised of the possibility of such damages.
                        </p>
                        <p>
                            In no event shall our total liability to you for all claims, damages, and causes of action exceed the total amount you have paid for the Services in the three (3) months preceding the event giving rise to the claim.
                        </p>

                        <h2>11. Indemnification</h2>
                        <p>
                            You agree to indemnify, defend, and hold harmless Managerize, its officers, employees, agents, and affiliates, from and against any claims, liabilities, damages, losses, and expenses (including attorneys' fees) arising from or related to your use of the Services, violation of these Terms, or infringement of any third-party rights.
                        </p>

                        <h2>12. Dispute Resolution</h2>
                        <p>
                            Any dispute arising from or relating to these Terms or your use of the Services shall be resolved through binding arbitration, rather than in court, except that you may seek injunctive or other equitable relief in a court of competent jurisdiction. The arbitration will be conducted in [location], and the decision of the arbitrator(s) will be final and binding.
                        </p>

                        <h2>13. Changes to the Terms</h2>
                        <p>
                            Managerize reserves the right to modify, amend, or update these Terms at any time. We will notify users of any material changes by posting the updated Terms on the Website. Your continued use of the Services after such changes will constitute your acceptance of the updated Terms.
                        </p>

                        <h2>14. Miscellaneous</h2>
                        <p>
                            <strong>Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of [State/Country].
                        </p>
                        <p>
                            <strong>Severability:</strong> If any provision of these Terms is found to be unenforceable or invalid, the remaining provisions will continue in full force and effect.
                        </p>
                        <p>
                            <strong>Force Majeure:</strong> Managerize will not be liable for any failure to perform its obligations due to events beyond its reasonable control, including but not limited to acts of God, labor disputes, internet outages, or government regulations.
                        </p>

                        <h2>15. Contact Us</h2>
                        <p>
                            If you have any questions about these Terms, please contact us at:
                        </p>
                        <p>
                            <strong>Managerize</strong><br />
                            [Insert Address]<br />
                            Email: <a href="mailto:contact@managerize.com">contact@managerize.com</a><br />
                            Phone: [Insert Phone Number]
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
