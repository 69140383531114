import React, { useState, useEffect } from "react";
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './style.css';
import '../../components/BurgerMenu/BurgerMenu.css';
const Home = () => {

  const [preloaderVisible, setPreloaderVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloaderVisible(false);
    }, 500); // 0.5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <link rel="stylesheet" href="css/bootstrap.min.css" />
      {/*====== PRELOADER PART START ======*/}
      <div className={`preloader ${preloaderVisible ? "" : "hide"}`}>
        <div className="loader">
          <div className="spinner">
            <div className="spinner-container">
              <div className="spinner-rotator">
                <div className="spinner-left">
                  <div className="spinner-circle" />
                </div>
                <div className="spinner-right">
                  <div className="spinner-circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====== PRELOADER PART ENDS ======*/}

      {/*====== HEADER PART START ======*/}
      <BurgerMenu />
      <header className="header-area">
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <div className="navbar-brand">
                    <img src="images/logo/logo.svg" alt="Logo" />
                  </div>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="page-scroll" href="#pricing">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#demo">
                          Book a demo
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="/terms-and-conditions">
                          Terms And Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* navbar collapse */}
                  <div className="navbar-btn d-sm-inline-block">
                    <a
                      className="main-btn"
                      data-scroll-nav={0}
                      href="/login"
                      rel="nofollow"
                    >
                      Login/Register
                    </a>
                  </div>
                </nav>
                {/* navbar */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* navbar area */}
        <div
          id="home"
          className="header-hero bg_cover"
          style={{ backgroundImage: "url(images/header/banner.png)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="header-hero-content text-center">
                  <h3 className="header-sub-title wow fadeInUp">
                    All-in-One Solution for
                  </h3>
                  <h2 className="header-title wow fadeInUp">
                    Workforce Management
                  </h2>
                  <p className="text wow fadeInUp">
                    Track employee performance, attendance, and details all in one
                    place. Simplify your HR tasks with our comprehensive tools.
                  </p>
                  <a href="/register" className="main-btn wow fadeInUp">
                    Get Started
                  </a>
                </div>
                {/* header hero content */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="header-hero-image text-center wow fadeIn"
                  data-wow-duration="1.3s"
                  data-wow-delay="1.4s"
                >
                  <img src="images/header/header-hero.png" alt="hero" />
                </div>
                {/* header hero image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
          <div id="particles-1" className="particles" />
        </div>
        {/* header hero */}
      </header>
      {/*====== HEADER PART ENDS ======*/}
      {/*====== BRAND PART START ======*/}
      {/*====== SERVICES PART START ======*/}
      <section id="features" className="services-area pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center pb-40">
                <div className="line m-auto" />
                <h3 className="title">
                  Streamlined and user-friendly,
                  <span>
                    {" "}
                    equipped with all the tools you need to manage your employees
                    effectively!
                  </span>
                </h3>
              </div>
              {/* section title */}
            </div>
          </div>
          {/* row */}
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-1.svg"
                    alt="shape"
                  />
                  <i className="lni lni-baloon"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Clean</h3>
                  </div>
                  <p className="text">
                    Effortless navigation and intuitive design for a seamless
                    employee management experience. Simplify your workflow with our
                    organized platform.
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-2.svg"
                    alt="shape"
                  />
                  <i className="lni lni-cog"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Robust</h3>
                  </div>
                  <p className="text">
                    Comprehensive tools and features to handle all aspects of
                    employee data, ensuring a solid foundation for your HR needs.
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-3.svg"
                    alt="shape"
                  />
                  <i className="lni lni-bolt-alt"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Powerful</h3>
                  </div>
                  <p className="text">
                    High-performance tools that give you full control over employee
                    management, enabling you to handle everything from scheduling to
                    performance reviews with ease.
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
          </div>
          {/* row */}
        </div>
        {/* container */}
      </section>
      {/*====== SERVICES PART ENDS ======*/}
      <section id="about">
        {/*====== ABOUT PART START ======*/}
        <div className="about-area pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-content mt-50">
                  <div className="section-title">
                    <div className="line" />
                    <h3 className="title">
                      Quick &amp; Easy <span>Workforce Management</span>
                    </h3>
                  </div>
                  {/* section title */}
                  <p className="text">
                    Our streamlined platform makes managing your workforce a breeze.
                    From employee scheduling to tracking performance, everything is
                    designed to simplify your daily tasks. Focus on what matters
                    most while we handle the complexities of workforce management.
                    Empower your team with tools that boost efficiency and
                    productivity, all in one easy-to-use system.
                  </p>
                </div>
                {/* about content */}
              </div>
              <div className="col-lg-6">
                <div className="about-image text-center mt-50">
                  <img src="images/about/about3.svg" alt="about" />
                </div>
                {/* about image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
          <div className="about-shape-1">
            <img src="images/about/about-shape-1.svg" alt="shape" />
          </div>
        </div>
        {/*====== ABOUT PART ENDS ======*/}
      </section>
      {/*====== PRICING PART START ======*/}
      <section id="pricing">
        <div className="cards">
          <div className="card shadow">
            <div className="pricing-text">
              <div className="pack">Basic</div>
              <div id="basic" className="price bottom-bar">
                $199.99
              </div>
              <div className="bottom-bar">500 GB Storage</div>
              <div className="bottom-bar">2 Users Allowed</div>
              <div className="bottom-bar">Send up to 3 GB</div>
              <button className="pricing-btn">Learn More</button>
            </div>
          </div>
          <div className="card active">
            <div className="pricing-text">
              <div className="pack">Professional</div>
              <div id="professional" className="price bottom-bar">
                $249.99
              </div>
              <div className="bottom-bar">1 TB Storage</div>
              <div className="bottom-bar">5 Users Allowed</div>
              <div className="bottom-bar">Send up to 10 GB</div>
              <button className="active-btn">Learn More</button>
            </div>
          </div>
        </div>
      </section>
      {/*====== PRICING PART END ======*/}
      {/*====== FOOTER PART START ======*/}
      <footer
        id="footer"
        className="footer-area pt-120"
        style={{
          backgroundImage: 'url(/images/footer/footer-bg.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}>
        <div className="container">
          <section id="demo"></section>
          <div
            className="subscribe-area wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="subscribe-content mt-45">
                  <h2 className="subscribe-title">
                    Want to see a Demo? <span>Enter your email</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="subscribe-form mt-50">

                  <input type="text" placeholder="Enter email" />
                  <button className="main-btn">Submit</button>

                </div>
              </div>
            </div>
            {/* row */}
          </div>
          {/* subscribe area */}
          <div className="footer-widget pb-100">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="footer-about mt-50 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="logo">
                    <img src="images/logo/logo.svg" alt="logo" />
                  </div>
                </div>
                {/* footer about */}
              </div>
              <div className="col-lg-5 col-md-7 col-sm-12">
                <div className="footer-link d-flex mt-50 justify-content-sm-between"></div>
                {/* footer link */}
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12">
                <div
                  className="footer-contact mt-50 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.8s"
                >
                  <div className="footer-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <ul className="contact">
                    <li>contact@managerize.com</li>
                  </ul>
                </div>
                {/* footer contact */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* footer widget */}
          <div className="footer-copyright">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright d-sm-flex justify-content-between">
                  <div className="copyright-content">
                    <p className="text">
                      Developed by Nikologic
                    </p>
                  </div>
                  {/* copyright content */}
                </div>
                {/* copyright */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* footer copyright */}
        </div>
        {/* container */}
        <div id="particles-2" />
      </footer>
      {/*====== FOOTER PART ENDS ======*/}
    </div>
  );
};

export default Home;
