import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { registerUser } from '../../apiService/apiService';
import './Register.css';

const Register = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        dateOfBirth: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        companyEmail: '',
        city: '',
        country: '',
        companyPhoneNumber: '',
        website: '',
    });

    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        try {
            const userData = {
                fullName: formData.fullName,
                email: formData.email,
                dateOfBirth: formData.dateOfBirth,
                MobilePhoneNumber: formData.mobileNumber,
                password: formData.password,
                companyName: formData.companyName,
                companyEmail: formData.companyEmail,
                city: formData.city,
                country: formData.country,
                companyPhoneNumber: formData.companyPhoneNumber,
                website: formData.website,
            };
            await registerUser(userData);

            navigate('/login');
        } catch (error) {
            console.error('Registration failed:', error);
            // Handle error
        }
    };

    return (
        <div
            className="register"
            style={{
                backgroundImage: "url(images/header/banner.png)",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
            }}
        >
            <Link to="/">
                <img
                    src="/images/logo/logo.svg"
                    alt="Logo"
                    className="logo"
                />
            </Link>
            <div className="container">
                <header>Registration</header>
                <form onSubmit={handleSubmit}>
                    <div className="form first">
                        <div className="details personal">
                            <span className="title">Personal Details</span>
                            <div className="fields">
                                <div className="input-field">
                                    <label>Full Name *</label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        placeholder="Enter your name"
                                        required
                                        value={formData.fullName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Email *</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Date of Birth *</label>
                                    <input
                                        type="date"
                                        name="dateOfBirth"
                                        required
                                        value={formData.dateOfBirth}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Phone Number *</label>
                                    <input
                                        type="number"
                                        name="mobileNumber"
                                        placeholder="Enter phone number"
                                        required
                                        value={formData.mobileNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Password *</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        required
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Confirm Password *</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        required
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="details company">
                            <span className="title">Company Details</span>
                            <div className="fields">
                                <div className="input-field">
                                    <label>Company Name *</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        placeholder="Enter company name"
                                        required
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Country *</label>
                                    <input
                                        type="text"
                                        name="country"
                                        placeholder="Enter country"
                                        required
                                        value={formData.country}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Company Email (optional)</label>
                                    <input
                                        type="email"
                                        name="companyEmail"
                                        placeholder="Enter company email"
                                        value={formData.companyEmail}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>City (optional)</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="Enter city"
                                        value={formData.city}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Company Phone Number (optional)</label>
                                    <input
                                        type="text"
                                        name="companyPhoneNumber"
                                        placeholder="Enter phone number"
                                        value={formData.companyPhoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Website (optional)</label>
                                    <input
                                        type="text"
                                        name="website"
                                        placeholder="Enter website"
                                        value={formData.website}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="input-field termsAndConditions">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="termsAndConditions"
                                        required
                                    />
                                    I agree to the <span> </span>
                                    <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>
                                </label>
                            </div>


                            <button className="nextBtn" type="submit">
                                <span className="btnText">Register</span>
                                <i className="uil uil-navigator" />
                            </button>
                        </div>
                    </div>
                </form>
                <div className="sign-in-prompt">
                    <p>Already have an account? <a href="/login">Sign In!</a></p>
                </div>
            </div>
        </div>
    );
};

export default Register;
