const API_URL = 'https://managerize-ckbpcub2fse8bvcn.westeurope-01.azurewebsites.net/api'; 
const fetchApi = async (endpoint, options = {}) => {
    const url = `${API_URL}${endpoint}`;

    const defaultOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
        },
        ...options,
    };

    try {
        const response = await fetch(url, defaultOptions);

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Error ${response.status}: ${errorMessage}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            const rawResponse = await response.text(); 
            return { message: rawResponse }; 
        }
    } catch (error) {
        console.error('Fetch API Error:', error);
        throw error;
    }
};

// Function to get the token from local storage
export const getToken = () => {
    return localStorage.getItem('token');
};

// Function to save the token to local storage
const saveToken = (token) => {
    localStorage.setItem('token', token);
};

// Function to remove the token from local storage (logout)
const removeToken = () => {
    localStorage.removeItem('token');
};


// Define your API functions
export const registerUser = async (userData) => {
    return await fetchApi('/auth/register', {
        method: 'POST',
        body: JSON.stringify(userData),
    });
};

export const loginUser = async (loginData) => {
    const response = await fetchApi('/auth/login', {
        method: 'POST',
        body: JSON.stringify(loginData),
    });

    if (response.token) {
        saveToken(response.token);
    } else {
        console.error('No token returned from login');
    }

    return response;
};

export const logoutUser = async () => {
    removeToken();
};

export const createEmployee = async (employeeData) => {
    return await fetchApi('/employee', {
        method: 'POST',
        body: JSON.stringify(employeeData),
    });
};

export const deleteEmployee = async (employeeId) => {
    return await fetchApi(`/employee/${employeeId}`, {
        method: 'DELETE',
    });
};

export const updateEmployee = async (employeeId, employeeData) => {
    return await fetchApi(`/employee/${employeeId}`, {
        method: 'PUT',
        body: JSON.stringify(employeeData),
    });
};

export const getCompanyId = async () => {
    return await fetchApi('/employee/company-id', {
        method: 'GET',
    });
};

export const getAllEmployeesForLoggedInUser = async () => {
    return await fetchApi('/employee/all', {
        method: 'GET',
    });
};

export const getEmployeeById = async (employeeId) => {
    return await fetchApi(`/employee/${employeeId}`, {
        method: 'GET',
    });
};

export const getHoursWorkedPerDayByEmployeeId = async (employeeId) => {
    return await fetchApi(`/attendance/hoursworkedbyday/${employeeId}`, {
        method: 'GET',
    });
};

export const getAttendanceByEmployeeId = async (employeeId) => {
    return await fetchApi(`/attendance/getattendance/${employeeId}`, {
        method: 'GET',
    });
};

export const calculateEmployeePay = async (employeeId, timePeriodType, startDate = null, endDate = null) => {
    // Construct the URL with query parameters
    const endpoint = `/attendance/calculatepay/${employeeId}?timePeriodType=${timePeriodType}` +
                     (startDate ? `&startDate=${startDate.toISOString().split('T')[0]}` : '') + // Keep only the date part
                     (endDate ? `&endDate=${endDate.toISOString().split('T')[0]}` : ''); // Keep only the date part

    return await fetchApi(endpoint, {
        method: 'GET',
    });
};

export const isClockedIn = async (employeeId) => {
    return await fetchApi(`/attendance/isclockedin/${employeeId}`, {
        method: 'GET',
    });
};

export const createGig = async (gigData) => {
    return await fetchApi('/gig/create', {
        method: 'POST',
        body: JSON.stringify(gigData),
    });
};

export const updateGig = async (gigId, gigData) => {
    return await fetchApi(`/gig/update/${gigId}`, {
        method: 'PUT',
        body: JSON.stringify(gigData),
    });
};

export const getAllGigsForLoggedInUser = async () => {
    return await fetchApi('/gig/all', {
        method: 'GET',
    });
};

export const getUpcomingGigs = async () => {
    return await fetchApi('/gig/upcoming', {
        method: 'GET',
    });
};

export const assignEmployeeToGig = async (gigId, employeeId) => {
    return await fetchApi(`/gig/assign-employee?gigId=${gigId}&employeeId=${employeeId}`, {
        method: 'POST',
        body: JSON.stringify({ gigId, employeeId: String(employeeId) }),
    });
};


export const unassignEmployeeFromGig = async (gigId, employeeId) => {
    return await fetchApi(`/gig/unassign-employee?gigId=${gigId}&employeeId=${employeeId}`, {
        method: 'POST',
        body: JSON.stringify({ gigId, employeeId }),
    });
};

export const getEmployeesForGig = async (gigId) => {
    return await fetchApi(`/gig/${gigId}/employees`, {
        method: 'GET',
    });
};

export const getMessagesForEmployeeAndCompany = async (employeeId) => {
    return await fetchApi(`/message/${employeeId}`, {
        method: 'GET',
    });
};

// Send a message to the employee's company
export const sendMessage = async (messageData) => {
    return await fetchApi('/message', {
        method: 'POST',
        body: JSON.stringify(messageData),
    });
};