import React, { useState, useEffect, useCallback } from 'react';
import './EmployeeDetailEarnings.css';
import { calculateEmployeePay } from '../../apiService/apiService';
import Loader from '../Loader/Loader';

const EmployeeDetailEarnings = ({ employeeId, paymentType }) => {
    const [timeframe, setTimeframe] = useState('weekly');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [customDate, setCustomDate] = useState('');
    const [earnings, setEarnings] = useState({ totalHours: 0, totalPay: 0 });
    const [loading, setLoading] = useState(false);

    const resetEarnings = () => {
        setEarnings({ totalHours: 0, totalPay: 0 });
    };

    const handleTimeframeChange = (e) => {
        const selectedTimeframe = e.target.value;
        setTimeframe(selectedTimeframe);
        resetEarnings(); // Clear the earnings when the timeframe changes

        // Reset date inputs for non-custom timeframes
        if (selectedTimeframe !== 'customRange' && selectedTimeframe !== 'customSingle') {
            setStartDate('');
            setEndDate('');
            setCustomDate('');
            calculateEarnings(selectedTimeframe, '', '', ''); // Call calculateEarnings immediately for other timeframes
        }
    };

    const handleDateChangeForRange = (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        if (newStartDate && newEndDate) {
            calculateEarnings('customRange', newStartDate, newEndDate, '');
        }
    };

    const handleDateChangeForSingle = (newCustomDate) => {
        setCustomDate(newCustomDate);
        if (newCustomDate) {
            calculateEarnings('customSingle', '', '', newCustomDate);
        }
    };

    const calculateEarnings = useCallback(async (selectedTimeframe, start, end, custom) => {
        let response;

        try {
            setLoading(true);

            const startDateObj = start ? new Date(start) : null;
            const endDateObj = end ? new Date(end) : null;
            const customDateObj = custom ? new Date(custom) : null;

            if (start && isNaN(startDateObj.getTime())) {
                throw new Error("Invalid start date");
            }
            if (end && isNaN(endDateObj.getTime())) {
                throw new Error("Invalid end date");
            }
            if (custom && isNaN(customDateObj.getTime())) {
                throw new Error("Invalid custom date");
            }

            if (selectedTimeframe === 'customRange') {
                response = await calculateEmployeePay(employeeId, 'customrange', startDateObj, endDateObj);
            } else if (selectedTimeframe === 'customSingle') {
                response = await calculateEmployeePay(employeeId, 'customday', customDateObj);
            } else {
                response = await calculateEmployeePay(employeeId, selectedTimeframe);
            }

            if (response && response.totalHoursWorked != null && response.totalPay != null) {
                setEarnings({
                    totalHours: response.totalHoursWorked,
                    totalPay: response.totalPay
                });
            } else {
                console.warn('No earnings found in response:', response);
                setEarnings({ totalHours: 0, totalPay: 0 });
            }
        } catch (error) {
            console.error('Error calculating earnings:', error);
            setEarnings({ totalHours: 0, totalPay: 0 });
        } finally {
            setLoading(false);
        }
    }, [employeeId]);

    useEffect(() => {
        // On initial render, load the weekly earnings
        calculateEarnings('weekly', '', '', '');
    }, [employeeId, calculateEarnings]);

    return (
        <div className="employee-detail-earnings">
            <div className="card">
                <h2 className="header">Employee Earnings</h2>

                {paymentType === 'Salary' ? (
                    <div className="salary-message">
                        Widget only available for <span className="hourly">hourly</span> employees
                    </div>
                ) : (
                    <>
                        <div className="timeframe-selector">
                            {['daily', 'weekly', 'monthly', 'customRange', 'customSingle'].map((value) => (
                                <label key={value}>
                                    <input
                                        type="radio"
                                        value={value}
                                        checked={timeframe === value}
                                        onChange={handleTimeframeChange}
                                    />
                                    {value === 'customRange' ? 'Custom Range' : value.charAt(0).toUpperCase() + value.slice(1).replace('Single', ' Day')}
                                </label>
                            ))}
                        </div>

                        {timeframe === 'customRange' && (
                            <div className="custom-date-picker">
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => handleDateChangeForRange(e.target.value, endDate)}
                                    className="date-input"
                                />
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => handleDateChangeForRange(startDate, e.target.value)}
                                    className="date-input"
                                />
                            </div>
                        )}

                        {timeframe === 'customSingle' && (
                            <div className="custom-single-date-picker">
                                <input
                                    type="date"
                                    value={customDate}
                                    onChange={(e) => handleDateChangeForSingle(e.target.value)}
                                    className="date-input"
                                />
                            </div>
                        )}

                        <Loader isLoading={loading} /> {/* Add loader component here */}

                        {!loading && (
                            <div className="earnings-display">
                                <h3 className="earnings-title">Total Hours Worked: {earnings.totalHours.toFixed(2)}</h3>
                                <h3 className="earnings-title">Total Pay: ${earnings.totalPay.toFixed(2)}</h3>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default EmployeeDetailEarnings;
