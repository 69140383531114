import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAttendanceByEmployeeId } from '../../apiService/apiService';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import EmployeeDetailViewMoreHoursWorkedTable from '../../components/EmployeeDetailViewMoreHoursWorkedTable/EmployeeDetailViewMoreHoursWorkedTable';
import Loader from '../../components/Loader/Loader';
import './EmployeeDetailViewMoreHoursWorked.css'; 

const EmployeeDetailViewMoreHoursWorked = () => {
  const { employeeId } = useParams();
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        setLoading(true);
        const data = await getAttendanceByEmployeeId(employeeId);
        setAttendanceData(data);
      } catch (err) {
        setError('Error fetching attendance data');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendance();
  }, [employeeId]);

  if (loading) return <Loader isLoading={loading} />;
  if (error) return <div>{error}</div>;

  return (
    <div className="employee-detail-attendance">
    <DashboardNavbar />
    <div className='content'>
        <EmployeeDetailViewMoreHoursWorkedTable
            attendanceData={attendanceData}
        />
    </div>
</div>
  );
};

export default EmployeeDetailViewMoreHoursWorked;
