import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './BurgerMenu.css';

const BurgerMenu = () => {
    return (
        <div className="burger-menu-container">
            <Menu>
                <a className="burger-menu-item" href="/">
                    Home
                </a>

                <a className="burger-menu-item" href="/#pricing">
                    Pricing
                </a>

                <a className="burger-menu-item" href="/#demo">
                    Book a demo
                </a>

                <a className="burger-menu-item" href="/terms-and-conditions">
                    Terms and Conditions
                </a>

            </Menu>
        </div>
    );
};

export default BurgerMenu;
