import React, { useState, useEffect } from 'react';
import { getAllEmployeesForLoggedInUser, getMessagesForEmployeeAndCompany, sendMessage } from '../../apiService/apiService';
import Loader from '../Loader/Loader'; // Import your Loader component
import './OverviewChat.css'; // Assuming you have updated styles for the chat

const OverviewChat = () => {
  const [employees, setEmployees] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [isChatView, setIsChatView] = useState(false); // Toggle between list and chat view
  const [employeesLoading, setEmployeesLoading] = useState(true); // Tracks loading state for employee list
  const [messagesLoading, setMessagesLoading] = useState(false); // Tracks loading state for messages (fetching or sending)

  // Fetch the list of employees
  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployeesLoading(true); // Start loading state for employees
      try {
        const data = await getAllEmployeesForLoggedInUser();
        setEmployees(data); // Set the employees list
      } catch (error) {
        console.error('Failed to fetch employees:', error);
      } finally {
        setEmployeesLoading(false); // Stop loading when done
      }
    };
    fetchEmployees();
  }, []);

  // Fetch messages for a specific employee
  useEffect(() => {
    if (selectedEmployee && isChatView) {
      const fetchMessages = async () => {
        setMessagesLoading(true); // Start loading state for messages
        try {
          const data = await getMessagesForEmployeeAndCompany(selectedEmployee.employeeId);
          setMessages(data); // Set the messages
        } catch (error) {
          console.error('Failed to fetch messages:', error);
        } finally {
          setMessagesLoading(false); // Stop loading when done
        }
      };
      fetchMessages();
    }
  }, [selectedEmployee, isChatView]);

  // Handle sending a new message
  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      setMessagesLoading(true); // Start loading state while sending the message
      try {
        const message = {
          employeeId: selectedEmployee.employeeId,
          content: newMessage,
          sender: 'Company', // Sender is the company
          companyId: selectedEmployee.companyId, // Assuming company ID is available in employee data
        };

        // Send message to API
        await sendMessage(message);

        // Update the chat with the new message
        setMessages((prevMessages) => [...prevMessages, message]);
        setNewMessage(''); // Clear the input field
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        setMessagesLoading(false); // Stop loading after the message is sent
      }
    }
  };

  // Toggle between employee list and chat view
  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    setIsChatView(true); // Switch to chat view
  };

  const handleBackToEmployeeList = () => {
    setIsChatView(false); // Go back to employee list
    setSelectedEmployee(null); // Clear selected employee
    setMessages([]); // Clear the messages
  };

  return (
    <div className="overview-chat">
      <div className="chat-card">
        <header className="chat-header">
          <h2>Employee Chats</h2>
        </header>

        {/* Employee List View */}
        {!isChatView ? (
          <div className="employee-list">
            {employeesLoading ? (
              <Loader isLoading={employeesLoading} />
            ) : (
              employees.map((employee) => (
                <div
                  key={employee.employeeId}
                  className={`employee-item ${selectedEmployee?.employeeId === employee.employeeId ? 'active' : ''}`}
                  onClick={() => handleSelectEmployee(employee)}
                >
                  <span>{employee.firstName} {employee.lastName}</span>
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="chat-section">
            <div className="back-container">
              <button className="back-button" onClick={handleBackToEmployeeList}>
                <img src="/icons/back.svg" alt="Back" />
              </button>
              <h3>Chat with {selectedEmployee?.firstName} {selectedEmployee?.lastName}</h3>
            </div>

            <div className="messages-container">
              {messagesLoading ? (
                <Loader isLoading={messagesLoading} />
              ) : (
                messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.sender === 'Company' ? 'company-message' : 'employee-message'}`}
                  >
                    <strong>{message.sender === 'Company' ? 'You' : `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}:</strong> {message.content}
                  </div>
                ))
              )}
            </div>

            <div className="message-input-container">
              <textarea
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
              ></textarea>
              <button 
                className="send-button" 
                onClick={handleSendMessage} 
                disabled={messagesLoading || !newMessage.trim()}
              >
                {messagesLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewChat;
